*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.bottomnavbar{
    position: fixed;
    width: 100%;
    z-index: 99;
    bottom: 0;
    height: 62px; 
    background: #fff;
    color: #89cdff;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);

    border-radius: 12px 12px 0 0;
    border-top: 1px solid #f5f5f5;
}

.nav-col {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    color: rgba(0,0,0,0.6);
}
.nav-col svg{
    height: 1.6em;
    width: 1.6em;
}
.nav-col p{
    font-size: 12px;
}

.navBtnAnimate{
    color: rgb(0 147 255);
    animation-name: animateZoom2;
    animation-duration: 0.2s;
    animation-timing-function: linear;
}

@keyframes animateZoom2 {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}